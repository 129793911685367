import React from 'react'
import styled from 'styled-components'
import BBB from "../../images/bbb.png"

const Wrapper = styled.div`
	margin-top: 30px;
	background: #FFFFFF;
	border: 1px solid #98DFFF;
	box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
	border-radius: 10px;
	padding: 35px;
	text-align: center;
	& h3 {
		font-size: 44px;
		line-height: 53px;
		margin-bottom: 20px;
	}
	& p {
		font-size: 18px;
		font-weight: bold;
		line-height: 29px;
		margin-bottom: 20px;
	}
	@media(max-width: 480px) {
		padding: 35px 25px;
	}
	@media(max-width: 380px) {
		padding: 30px 15px;
	}
`
const BBBPan = styled.div `
	border-radius: 5px 10px 10px 5px;
	display: inline-block;
	background: white;
	box-shadow: 0px 4px 12px rgba(0,0,0,0.2);
	& img {
		margin: 4px 8px;
		vertical-align: middle;
	}
	& div {
		border-radius: 0px 5px 5px 0px;
		padding: 0 7px;
		width: 150px;
		vertical-align: middle;
		text-transform: uppercase;
		background: #035A78;
		display: inline-block;
		font-size: 18px;
		font-family: Chakra Petch;
		font-weight: 700;
		color: white;
	  line-height: 18px;
	  height: 73px;
    padding: 18px 8px;
	}
`
const Rating= styled.div`
	margin-left: 4px;
	display: inline-block;
	vertical-align: bottom;
	box-shadow: 0px 4px 12px rgba(0,0,0,0.2);
	width: 80px;
	height: 73px;
	border-radius: 5px;
	background: #035A78;
	color: white;
	text-align: center;
	padding: 7px;
	& div {
		font-size: 36px;
		font-family: Chakra Petch;
		font-weight: 700;
		margin: 5px 0;
	}
	& p {
		font-size: 14px;
		line-height: 1.2;
		color: white !important;
		margin: 0;
	}
`

const AccreditedBusiness = () => {
	return(
		<Wrapper>
			<BBBPan>
				<img src={BBB} alt="business" />
				<div>accredited business</div>
			</BBBPan>
			<Rating>
				<div>A+</div>
				<p>RATING</p>
			</Rating>
		</Wrapper>
	)
}

export default AccreditedBusiness