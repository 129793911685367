import React from 'react'
import styled from 'styled-components'
import Img from "gatsby-image"
import { Link, graphql } from 'gatsby'
import SEO from "../components/seo"
import Layout from '../components/layout'
import CallToAction from "../components/CallToAction"
import { BreadCrumb, LeftPannel, RightPannel, MarkdownContent } from "../components/Section"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import RightForm from '../components/RightForm'
import generateHTML from '../utils/generateHTML'

const Wrapper = styled.div`
	position: relative;
	padding-top: 250px;
	padding-bottom: 70px;
	max-width: ${props=>props.maxWidth};
	width: 100%;
	margin: auto;
	& h1 {
		color: #062C44;
	}
	& .gatsby-image-wrapper {
		margin-bottom: 50px;
	}
	@media(max-width: 768px){
		padding: 150px 0px 70px;
		& .gatsby-image-wrapper {
			margin-bottom: 30px;
		}
	}
	@media(max-width: 480px){
		padding: 150px 0 70px 0px;
	}
`
Wrapper.defaultProps = {
	maxWidth: "890px"
}

class BuyingGuideTemplate extends React.Component {
	render() {
		const pageData = this.props.data.contentfulContentPage
		if (pageData.rightFormTitle !== null) {
			return(
				<Layout location={this.props.location}>
					<SEO title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
					<div className="container">
						<BreadCrumb top="210px" mTop="120px"><Link to="/">Home</Link> / <Link to="/buying-guide">Buying Guide</Link> / <span>{pageData.title}</span></BreadCrumb>
						<Wrapper maxWidth="100%">
		      		<LeftPannel>
								<h1>{pageData.title}</h1>
								<Img fluid={pageData.image.fluid} alt={`image for ${pageData.title}`} />
								<MarkdownContent dangerouslySetInnerHTML={{__html: generateHTML(pageData.content.content)}} />
							</LeftPannel>
							<RightPannel mt="100px">
								<RightForm title={pageData.rightFormTitle} location={this.props.location} formName={`${pageData.title}-Contact Form`} />
								<DesignPlan />
								<AccreditedBusiness />
							</RightPannel>
						</Wrapper>
					</div>
					<CallToAction />
				</Layout>
			)
		} else {
			return(
				<Layout location={this.props.location}>
					<Wrapper>
						<BreadCrumb mTop="120px">Home / Buying Guide / <span>{pageData.title}</span></BreadCrumb>
						<h1>{pageData.title}</h1>
						<Img fluid={pageData.image.fluid} alt={`image for ${pageData.title}`} />
						<MarkdownContent dangerouslySetInnerHTML={{__html: generateHTML(pageData.content.content)}} />
					</Wrapper>
					<CallToAction />
				</Layout>
			)	
		}
	}
}

export default  BuyingGuideTemplate

export const pageQuery = graphql`
  query buyingGuideQuery($id: String!) {
  	contentfulContentPage(id: { eq: $id }) {
      title
      metaTitle
      metaDescription {
	      metaDescription
	    }
      content {
        content
      }
      createdAt(fromNow: true)
      image {
        fluid(maxWidth: 1000) {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      rightFormTitle
	  }
  }
`