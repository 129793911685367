import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"

import { Divider } from "../Section"
import {
  Button,
  CustomValidationMessage,
  RequiredTriangle,
} from "../../components/FormStyle"

const FormWrapper = styled.form`
  background: #ffffff;
  border: 1px solid #98dfff;
  box-shadow: 0px 15px 45px rgba(152, 223, 225, 0.2);
  border-radius: 10px;
  padding: 49px 61px;

  & .h3 {
    font-size: 24px;
    line-height: 29px;
    margin-top: -66px;
    background: white;
    margin-bottom: 40px;
    text-align: center;
  }

  & label {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #062c44;
    display: block;
    margin-top: 26px;
  }

  & input {
    width: 100%;
    font-size: 16px;
    font-weight: 800;
    line-height: 19px;
    padding: 10px 0px;
    color: #676b79;
    border: 0px;
    &:focus {
      outline: none !important;
    }
  }

  & textarea {
    width: 100%;
    font-size: 16px;
    font-weight: 800;
    line-height: 19px;
    padding: 10px 0px;
    border: 0px;
    resize:none;
    &:focus {
      outline: none !important;
    }
  }
`

const Field = styled.div`
  position: relative;
`

const CustomRequired = styled(CustomValidationMessage)`
  top: 54px;
  left: -20px;
`

class RightForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      zipcode: "",
      comment: "",
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      zipcodeRequire: false,
      commentRequire: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  // Use the submitted data to set the state
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (id === "zipcode") {
      this.setState({
        zipcodeRequire: false,
      })
    }
    if (id === "comment") {
      this.setState({
        commentRequire: false,
      })
    }
  }
  handleSubmit = event => {
    const { location, formName } = this.props
    event.preventDefault()
    if (this.state.first_name === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (this.state.last_name === "") {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (this.state.phone_no === "") {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.zipcode === "") {
      this.setState({
        zipcodeRequire: true,
      })
    } else if (this.state.comment === "") {
      this.setState({
        commentRequire: true,
      })
    } else {
      const data = {
        source: location.origin,
        form: formName,
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        zipcode: this.state.zipcode,
        comment: this.state.comment,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            comment: "",
          })
          navigate("/thank-you-quick-contact/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    const { title } = this.props
    return (
      <FormWrapper id="rightform" onSubmit={this.handleSubmit}>
        <div className="h3">{title}</div>
        <div>
          <label>First Name</label>
          <Field>
            <input
              type="text"
              placeholder="Please Enter Your First Name"
              id="first_name"
              onChange={this.handleChange}
              pattern="[A-Za-z\s]+$"
              required
            />
            <CustomRequired
              display={this.state.fNameRequire ? "block" : "none"}
            >
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </Field>
          <Divider my="0px" />
          <label>Last Name</label>
          <Field>
            <input
              type="text"
              placeholder="Please Enter Your Last Name"
              id="last_name"
              onChange={this.handleChange}
              pattern="[A-Za-z\s]+$"
              required
            />
            <CustomRequired
              display={this.state.lNameRequire ? "block" : "none"}
            >
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </Field>
          <Divider my="0px" />
          <label>Email</label>
          <Field>
            <input
              type="email"
              placeholder="Please Enter Your Email Address"
              id="email"
              onChange={this.handleChange}
              required
            />
            <CustomRequired
              display={this.state.emailRequire ? "block" : "none"}
            >
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </Field>
          <Divider my="0px" />
          <label>Phone Number</label>
          <Field>
            <input
              type="text"
              placeholder="Please Enter Your Phone Number"
              id="phone_no"
              onChange={this.handleChange}
              pattern="[0-9]{10}"
              required
            />
            <CustomRequired
              display={this.state.phoneRequire ? "block" : "none"}
            >
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </Field>
          <Divider my="0px" />
          <label>ZipCode</label>
          <Field>
            <input
              type="text"
              placeholder="Please Enter Your ZipCode"
              id="zipcode"
              onChange={this.handleChange}
              pattern="[0-9]+$"
              required
            />
            <CustomRequired
              display={this.state.zipcodeRequire ? "block" : "none"}
            >
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </Field>
          <Divider my="0px" />
          <label>Message</label>
          <Field>
            <textarea
              placeholder="Write your message here…"
              rows="3"
              id="comment"
              onChange={this.handleChange}
              required
            />
            <CustomRequired
              display={this.state.commentRequire ? "block" : "none"}
            >
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomRequired>
          </Field>
          <Divider my="0px" />
          <div style={{ marginTop: 30 }}>
            <Button width="100%" color="white" type="submit" form="rightform">
              <div>Submit</div>
            </Button>
          </div>
        </div>
      </FormWrapper>
    )
  }
}

export default RightForm
